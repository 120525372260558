
/* Mobile CSS for Screensizes with AT LEAST THAN 900PX HEIGHT*/
@media only screen and (max-width: 750px) and (min-height: 900px) {
  .header > h1 {
    font-size: 2.5rem;
  }
  
  .header > h1 > span > img{
    height: 110px;
    width: 130px;
  }

  .navItem{
    width: 60px;
    height: 30px;
    font-size: 12px;
  }

  .navItem > a{
    padding: 0.5rem 0.2rem;
  }
  
  .stufenInfo > div {
    line-height: 20px;
  }

  .pfadilaedeli {
    padding: 1vh 1vw;
    right: 0px;
    width: 100vw;
    border: none;
    bottom: 5vh;
  }
}

@media only screen and (max-width: 450px) and (min-height: 900px) {
  header {
    height: 17vh;
  }

  .header > h1 {
    font-size: 1.7rem;
  }

  .header > h1 > span > img{
    height: 50px;
    width: 60px;
  }

  .navItem{
    width: 60px;
    height: 30px;
    font-size: 12px;
  }

  .navItem > a{
    padding: 0.5rem 0.2rem;
  }

  .stufenInfo > div {
    line-height: 20px;
  }

  .pfadilaedeli {
    padding: 1vh 1vw;
    right: 0px;
    width: 100vw;
    border: none;
    bottom: 5vh;
  }
}