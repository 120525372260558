body {
  width: 100%;
  height: 95vh;
  font-size: 14px;
  line-height: inherit;
}

input {
  border: none
}

/* Top Tier Sizing Classes*/

.bodyWithoutFooter {
  height: 95vh;
  overflow-y: scroll;
}

/* Header classes */
.header {
  position: fixed;
  width: 100%;
  z-index: 20;
  height: 17vh;
  background-color: white;
  margin: 0px;
}

.header>h1 {
  font-weight: 300;
  font-size: 4.5rem;
  margin: inherit;
  height: 9vh;
}

.header>h1>span {
  margin: auto 15px 20px 15px;
}

.header>h1>span>img {
  margin: 10px;
  position: relative;
  top: -0.2vh;
  height: 110px;
  width: 130px;
}

/* Footer Classes */
.footer {
  position: fixed;
  bottom: 0px;
  width: 100%;
  height: 5vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 5px;
}

.footer>nav {
  padding: 0px;
  height: 100%;
  padding-left: 5px;
}

.footerItem {
  width: 80px;
  text-align: center;
  vertical-align: middle;
  margin: 1px;
}

/* Stufen Classes */
.stufenInfo {
  border-top: 1px solid;
  border-bottom: 1px solid;
  margin-bottom: 10px;
  margin-top: 10px;
}

.stufenInfo>div {
  line-height: 45px;
}

/* Bekleidung Classes */
.bekleidung_img {
  margin: 10px auto 0 auto;
  display: block;
  /* width: 100%; */
}

.pfadilaedeli {
  padding: 10px;
  position: fixed !important;
  right: 20px;
}

.pfadilaedeli>div {
  border: 1px solid #aaaaaa;
  border-radius: 5px;
  background-color: #eeeeee;
  padding: 10px;
}

.imgText-bekleidung {
  text-align: center;
  margin-top: 10px;
}

/* Utility Classes */
.container>.row>p {
  font-size: 16px;
}

.navItem {
  width: 100px;
  text-align: center;
  vertical-align: middle;
  height: 50px;
  margin: 1px;
}

.internSection {
  margin-right: 0px;
  margin-left: 0px;
}

.dropdown-toggle {
  transition: 500ms;
  color: black;
}

.dropdown-toggle:hover {
  background-color: #cccccc;
  transition: 500ms;
}

.dropdown-item>a {
  text-decoration: none;
  color: black;
  border: none;
}

.dropdown-item>a:hover {
  text-decoration: none;
  color: black;
  border: none;
}

.navItem>a {
  color: black;
}

.nav-link:focus {
  border-color: #cccccc #cccccc #bbbbbb !important;
  background-color: #cccccc;
}

.nav-tabs {
  border-bottom: none !important;
}

.menuButton {
  color: grey;
  background-color: #dddddd;
  width: 100%;
  height: 100%;
  border: none;
  font-weight: bold;
  font-size: 12px;
  transition: 500ms;
}

.navItem :hover {
  background-color: #cccccc;
  transition: 500ms;
}

.newsBlog {
  margin: 10px;
  height: 100%;
}

.linkedDoc {
  height: 90%;
  max-width: 80% !important;
}

.linkedDoc>div {
  height: 100%;
}

.closeButton {
  margin: 2px 12px 2px 2px;
  padding: 3px !important;
  float: right;
  width: 20px !important;
  cursor: pointer;
}

.closeButtonMobile {
  position: fixed;
  margin: 2px 2px 2px 2px;
  font-size: 40px;
  right: 0px;
  z-index: 20;
  padding: 3px !important;
  width: 40px !important;
  color: #1b1b1b !important;
}


.fa {

  margin-right: 0px;
  margin-top: 0px;
  color: #555555;
  margin-right: 20px;
}

.fa {
  padding: 20px;
  width: 50px;
  text-align: center;
  text-decoration: none;
}

.fa:hover {
  opacity: 0.7;
}

.fa-facebook-square {
  padding: 0px !important;
}

.abteilung_front {
  width: 100%;
  margin-top: 5px;
}

.stufen_header {
  text-align: center;
  transition: 1000ms;
}

.stufen_header:hover {
  background-color: #cccccc;
  transition: 1000ms;
}

.stufen_container {
  width: 90%;
  margin: 30px auto auto auto;
}

.stufen_link {
  text-decoration: none;
  color: black;
}

.info_link {
  text-decoration: none;
  color: black;
}

.stufen_link:hover {
  text-decoration: none;
  color: black;
}

.content {
  min-height: 400px;
  margin-bottom: 45px;
}

.overflow {
  overflow-y: scroll;
}

.container {
  height: 100%;
  max-width: 100%;
}

.container>.row {
  height: inherit;
}

.container>.row>div {
  height: inherit;
}

.card {
  height: 100%;
}

.restrainedHeight {
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
}

.margin-left {
  margin-left: 2%;
}

.margin-bottom {
  margin-bottom: 5px;
}

.no-margin-bottom {
  margin-bottom: 0px;
}

.line-height {
  line-height: 45px;
}

.link-to-doc {
  color: #007bff;
  cursor: pointer;
}

.margin-top {
  margin-top: 15px;
}

.disabled-link {
  color: #aaaaaa;
  cursor: pointer;
}

.red-border {
  border: red 2px solid;
  padding: 5px;
}

.photoNav {
  width: 100%;
  font-size: 16px;
  text-align: left;
  float: left;
}

.photoNavContainer {
  height: 100%;
}

.yearButton {
  font-weight: bold;
  cursor: pointer;
  color: #666666 !important;
  border-bottom: 2px solid #cccccc;
  transition: 300ms;
  padding-left: 10px;
}

.yearButton:hover {
  background-color: #eeeeee;
  text-decoration: none;
  transition: 300ms;
}

.collapseContainer>.yearButton {
  padding-left: 0px;
}

.listIcon {
  margin-right: 3px;
}

.float-left {
  float: left;
}

.eventcard {
  margin: 10px;
}

.max-width {
  width: 100%;
}

.remove-link-style {
  text-decoration: none;
  color: black;
}

.remove-link-style:hover {
  text-decoration: none;
  color: black;
}

.photo_card {
  border: solid 2px #eeeeee !important;
  height: 100%;
}

.photo_card:hover {
  background-color: #eeeeee;
  transition: 500ms;
  border: solid 2px #eeeeee;
}

.photos {
  margin: 3px;
  transition: 500ms;
  border: 3px solid #ffffff;
}

.photos:hover {
  border: 3px #cccccc solid;
  transition: 500ms;
}

.photo_modal {
  height: 95%;
  width: 80% !important;
  max-width: none !important;
}

.photo_modal>div {
  height: 100%;
  width: 100%;
}

.modal-body::-webkit-scrollbar {
  display: none;
}

.modal-body {
  display: block;
  overflow: scroll;
  padding: 0px
}

.carousel-inner {
  height: inherit;
}

.carousel-item {
  height: 100%;
  width: 100%;
}

.carousel-item>div {
  height: inherit;
  width: inherit;
}

.carousel-item>div>div>img {
  display: block;
  margin: auto;
}

.carousel-control-next-icon {
  background-color: #aaaaaa;
  opacity: 0.5;
}

.carousel-control-next-icon:hover {
  opacity: 0.5;
}

.carousel-control-prev-icon {
  background-color: #aaaaaa;
  opacity: 0.5;
}

.carousel-control-prev-icon:hover {
  opacity: 0.5;
}

.slider-photos {
  height: 100%;
}

.photo_item {
  height: inherit;
}

.photo_item>div {
  height: inherit;
}

.photo_item>div>div {
  height: inherit;
}

.photo_item>div>div>img {
  height: inherit;
}

.mobileOverlay>div>div>.carousel-item>div>div>img {
  width: 100%;
}

.sectionHeader {
  border-bottom: 1px solid;
  padding-bottom: 10px;
  margin-top: 10px;
}

.border-bottom-black {
  border-bottom: 1px solid black;
}

.margin-all {
  margin: 5px 0px 5px 0;
}

.margin-center {
  margin: auto;
  display: block;
  width: 100%;
}

.wrongPW {
  color: red;
}

.break-word {
  overflow-wrap: break-word;
}

.collapse {
  width: 100%;
  display: block;
}

.collapseItem {
  padding-left: 7px;
}

.contactPic {
  max-width: 300px;
  margin: 5px auto 5px auto;
}

.kontakt-collapse {
  cursor: pointer;
}

.vereinsangaben {
  font-size: 25px;
}

.notFoundContainer {
  margin: 20px 100px 0 100px;
  height: 700px;
  background-image: url('http://localhost:5000/notfound.jpg');
  background-size: cover;
}

.notFoundContainer>div {
  opacity: 0.7;
}

.linkIcon {
  height: 40px;
  border: 1px grey solid;
  border-radius: 2px;
  float: right;
  margin-right: 10px;
}

.overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  transition: 0.3s;
  z-index: 20;
}

.showOverlay {
  cursor: pointer;
  visibility: visible;
  opacity: 1;
  overflow-y: auto;
  overflow-x: auto;
  transition: opacity 0.2s linear;
}

.hideOverlay {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s 0.2s, opacity 0.2s linear;
}

.overlay>img {
  height: 95%;
  left: 50vw;
  top: 50vh;
  transform: translate(-50%, -50%);
  position: relative;
  margin: auto;
}

.mobileOverlay>img {
  width: 100vw;
}

.mobileOverlay {
  height: 100vh;
  top: 0px;
  left: 0px;
  z-index: 20;
  position: fixed;
  overflow-y: scroll;
}

.normalImg {
  width: 100%;
  position: relative;
  cursor: pointer;
}