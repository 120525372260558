
/* Mobile CSS for Screensizes with LESS THAN 900PX HEIGHT*/
@media only screen and (min-width: 1000px) and (max-height: 900px) {
  .header {
    height: 20vh;
  }
  
  .header > h1 {
    font-size: 2.7rem;
    height: 12vh;
    font-weight: 300;
  }
  
  .header > h1 > span > img{
    height: 80px;
    width: 94px;
  }

  .footer {
    height: 5vh
  }

  .sectionHeader {
    font-size: 1.2rem
  }

  .internSection{
    padding: 0px;
  }

  .navItem{
    width: 80px;
    height: 25px;
    font-size: 15px;
    vertical-align: middle;
  }

  .navItem > a{
    padding: 0.5rem 0.2rem;
  }
  
  .stufenInfo > div {
    line-height: 20px;
  }
}

@media only screen and (max-width: 750px) and (max-height: 900px) {
  .header {
    height: 30vh;
  }
  
  .header > h1 {
    font-size: 1.7rem;
    height: 18vh;
  }
  
  .header > h1 > span > img{
    height: 50px;
    width: 60px;
  }

  .navItem{
    width: 90px;
    height: 30px;
    font-size: 12px;
  }

  .navItem > a{
    padding: 0.5rem 0.2rem;
  }
  
  .stufenInfo > div {
    line-height: 20px;
  }

  .pfadilaedeli {
    padding: 1vh 1vw;
    right: 0px;
    width: 100vw;
    border: none;
    bottom: 5vh;
  }

  .newsBlog {
    width: 60%;
    margin: auto;
  }
}

@media only screen and (max-width: 450px) and (max-height: 900px) {
  .header {
    height: 17vh;
  }

  .header > h1 {
    font-size: 1.7rem;
    height: 10vh;
  }

  .header > h1 > span > img{
    width: 60px;
  }

  .navItem{
    width: 60px;
    height: 30px;
    font-size: 12px;
  }

  .navItem > a{
    padding: 0.5rem 0.2rem;
  }

  .stufenInfo > div {
    line-height: 20px;
  }

  .pfadilaedeli {
    padding: 1vh 1vw;
    right: 0px;
    width: 100vw;
    border: none;
    bottom: 5vh;
  }

  .newsBlog {
    width: 100%;
  }

  .navbar-toggler {
    height: 25px;
    padding: 0rem 0.75rem;
  }

  .navbar-toggler > span {
    height: 20px;
  }
}