
/* Mobile CSS for Screensizes with LESS THAN 450PX HEIGHT*/
@media only screen and (max-width: 750px) and (max-height: 450px) {
  .header {
    height: 31vh;
  }
  
  .header > h1 {
    font-size: 1.7rem;
    height: 18vh;
  }
  
  .header > h1 > span > img{
    height: 50px;
    width: 60px;
  }

  .restrainedHeight{
    top: 31vh;
  }
  
  .stufen_link > img {
    width: 50%;
  }

  .navItem{
    width: 90px;
    height: 30px;
    font-size: 12px;
  }

  .navItem > a{
    padding: 0.5rem 0.2rem;
  }
  
  .stufenInfo > div {
    line-height: 20px;
  }

  .pfadilaedeli {
    padding: 1vh 1vw;
    right: 0px;
    width: 100vw;
    border: none;
    bottom: 5vh;
  }

  .newsBlog {
    width: 60%;
    margin: auto;
  }

  .navbar-toggler {
    height: 30px;
    padding: 0.2rem 0.75rem 0.2rem 0.75rem;
  }

  .navbar-toggler > span {
    height: 25px;
  }

  .mobile_fontSize > .card-title{
    font-size: 0.9rem;
  }

  .mobile_fontSize > .card-subtitle{
    font-size: 0.9rem;
  }

  .mobile_fontSize > .card-text{
    font-size: 0.7rem;
  }
}

@media only screen and (max-width: 450px) and (max-height: 450px) {
  .header > h1 {
    font-size: 1.7rem;
  }

  .header > h1 > span > img{
    height: 50px;
    width: 60px;
  }

  .navItem{
    width: 60px;
    height: 30px;
    font-size: 12px;
  }

  .navItem > a{
    padding: 0.5rem 0.2rem;
  }

  .restrainedHeight{
    top: 30vh;
  }

  .stufenInfo > div {
    line-height: 20px;
  }

  .pfadilaedeli {
    padding: 1vh 1vw;
    right: 0px;
    width: 100vw;
    border: none;
    bottom: 5vh;
  }
}